import Core from '@/core/module';
import 'slick-carousel';

export default class Imageslider extends Core {
    init() {
        this.$el.find('ul').slick({
            dots: true,
            prevArrow: '<button class="btn btn--slider"><i class="icon-arrow-left"></i></button>',
            nextArrow: '<button class="btn btn--slider btn--slider--right"><i class="icon-arrow-right"></i></button>',
        });
    }
}