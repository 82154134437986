import _ from 'lodash';

const aa = new class gmapsloader {
    constructor() {
        this._appended = (window.google && window.google.maps) ? true : false;
        this._loaded = (window.google && window.google.maps) ? true : false;
        this._queue = []; 
    }

    load(callback) {
        if(!siteOptions.mapsApiKey) {
            console.error('siteOptions.mapsApiKey not defined!');
            return;
        }

        this._queue.push(callback); 

        window.mapLoaded = () => {
            this._loaded = true;
            this.runQueue();
        }

        if(this._appended == false && this._loaded == false) {
            this.appendScript();
        }
        else if(this._appended == true && this._loaded == true) {
            this.runQueue();
        }
    }

    runQueue() {
        _.each(this._queue, (item) => {
            item.call(window, google.maps);
        });

        this._queue = [];
    }

    appendScript() {
        this._appended = true;
        $('head').append(`<script src="https://maps.googleapis.com/maps/api/js?key=${siteOptions.mapsApiKey}&callback=window.mapLoaded&libraries=places"></script>`);
    }
}

export default aa;