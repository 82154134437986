import Core from '@/core/module';
import {TweenMax} from 'gsap';
import '@/vendor/tweenmax/easing/CustomEase';

export default class Menu extends Core {
    init() {  
        this.addEventListeners();
    }

    open() {
        this.$el.addClass('open');
        this.$el.find('.hamburger').addClass('hamburger--open');
        TweenMax.to(this.$el, .45, {height: this.$el[0].scrollHeight, ease: Quad.easeOut, onComplete: () => {
            this.$el.css('height', 'auto');
        }});
    }

    close() {
        this.$el.removeClass('open');
        this.$el.find('.hamburger').removeClass('hamburger--open');
        TweenMax.to(this.$el, .45, {height: 66});
    }

    toggle() {
        if(this.$el.hasClass('open')) {
            this.close();
        }
        else {
            this.open();
        }
    }

    addEventListeners() {
        $(document).on('click', '.menutoggle', (e) => {
            e.preventDefault();

            this.toggle();
        });
    }
}