export default {
    set: function (name, data) {
        const storage = data.session ? sessionStorage : localStorage;

        if(typeof data.value == 'object') {
            data.value = JSON.stringify(data.value);
        }

        storage.setItem(name, data.value);
    },

    get: function(name, data){
        const storage = data.session ? sessionStorage : localStorage;

        let storageValue = storage.getItem(name);

        switch(data.dataType) {
            case Number:
                storageValue = parseFloat(storageValue);
                break;
            case Boolean:
                storageValue = storageValue ? true : false;
                break;
            case JSON:
                storageValue = JSON.parse(storageValue);
                break;                        
            case String:
                storageValue = storageValue.toString();
                break;
        }

        return storageValue;
    },

    delete: function(name) {
        const storage = data.session ? sessionStorage : localStorage;
        
        storage.removeItem(name);
    }
}