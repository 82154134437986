import Core from '@/core/module';
import { TweenMax } from 'gsap';

export default class Search extends Core {
    init() {
        this.open = false;

        $('.mainnav__search').on('click', (e) => {
            e.preventDefault();

            if (this.open) {
                TweenMax.to('.search', .2, { top: -200 });
            } else {
                TweenMax.to('.search', .2, { top: 64 });
            }

            this.open = !this.open;
        });
    }
}