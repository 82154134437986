import Core from '@/core/module';
import Storage from '@/helpers/storage';
import API from '@/helpers/api';

export default class InfiniteScroll extends Core {
    init() {  
        this.setDefaults({
            offset: 200,
            linkElement: '[infinite]',
            loadClass: 'infinite--loading',
            restoreScroll: true,
            formData: null,
            method: 'get',
            hashtagRestore: true
        });

        this.restoreScroll();

        this.addEventListeners();
    }

    addEventListeners() {
        this.$el.on('click', this.options.linkElement, (e) => {
            e.preventDefault();
            this.checkScroll();
        });

        $(window).on('scroll.infinite', () => {
            this.checkScroll();

            Storage.set(`infinite.scrollPosition[${window.location.pathname}]`, {
                value: {
                    path: window.location.pathname,
                    scrollPosition: $(window).scrollTop()
                },
                session: true
            });
        });
    }

    restoreScroll() {
        let scrollTopTimer;

        /**
         * Als er een ! in de hash is, scroll positie niet restoren
         */
        if(window.location.hash != '#!' && this.options.hashtagRestore) {
            window.location.hash = '!';
            return;
        }

        const restorePos = Storage.get(`infinite.scrollPosition[${window.location.pathname}]`, {
            dataType: JSON,
            session: true
        });

        if(!restorePos) {
            return;
        }

        $(window).on('mousewheel.infinite', function(){
            clearInterval(scrollTopTimer);
        });

        scrollTopTimer = setInterval(function(){
            $(window).scrollTop(restorePos.scrollPosition);

            console.log('tick!');

            if($(window).scrollTop() == restorePos.scrollPosition) {
                console.log('clearrr!');
                clearInterval(scrollTopTimer);
            }
        }, 100);
    }

    checkScroll() {
        const scrollTop = $(window).scrollTop(),
              height = this.$el.height(),
              windowHeight = $(window).height(),
              elementHeight = this.$el.height(),
              offset = this.$el.offset();

        if((windowHeight + scrollTop + this.options.offset) > (elementHeight + offset.top)) {
            this.loadMore();
        }
    }

    forceStop() {
        if(this.ajax) {
            this.ajax.abort();

            this.$el.removeClass(this.options.loadClass);
        }
    }
    
    pause() {
        this._pause = true;
    }

    start() {
        this._pause = false;
        this.checkScroll();
    }

    loadMore() {
        let data = null;
        
        // niks laden als er al iets geladen wordt. of als infinite op pauze is gezet.
        if(this.loading || this._pause) {
            return;
        }

        // niet meer lade als er niks meer is.
        if(this.$el.find(this.options.linkElement).length == 0) {
            return;
        }

        this.$el.addClass(this.options.loadClass);
        this.loading = true;

        if(this.options.formData && this.options.formData.length > 0) {
            data = this.options.formData.serialize();
        }

        this.ajax = $.ajax({
            url: this.$el.find(this.options.linkElement).attr('href'),
            data: data,
            method: this.options.method,
            dataType: 'HTML',
            success: (html) => {
                const $html = $(html);

                this.$el.find(this.options.linkElement).remove();

                this.$el.removeClass(this.options.loadClass);

                this.$el.append($html);

                this.trigger('loaded', $html);

                setTimeout(() => {
                    this.loading = false;
                    this.checkScroll();
                }, 500);
            },
            error: () => {
                if(textStatus != 'abort') {
                    console.error(textStatus);
                }
                //window.location = this.$el.find(this.options.linkElement).attr('href');
            },
        });
    }
}