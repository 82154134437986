import Core from '@/core/module';
import Vue from 'vue';

export default class Headersearch extends Core {
    init() {
        import('./components/App.vue').then((app) => {
            const App = app.default;

            new Vue({
                el: this.$el[0],
                render: h => h(App)
            });
        });
    }
}
