import _ from "lodash";

export default class Events {
    constructor() {
        this.events = [];
    }

    on(_eventNames, _eventFunction) {
        const _this = this;
        const _events = _eventNames.split(',');
       
        _.each(_events, function(_event){
            _event = _event.trim();
            
            const eventName = _event.split('.')[0];
            const eventClass = _event.split('.')[1];
            const eventFunction = _eventFunction;

            const event = {eventName, eventClass, eventFunction};

            _this.events.push(event);
        });
        
        return this;
    }

    off(event) {
        // remove all events
        if(!event) {
            this.events = [];
            return this;
        }

        const eventName = event.split('.')[0];
        const eventClass = event.split('.')[1];

        this.events = _.filter(this.events, function(event){
            if(event.eventName == eventName && (!eventClass || (eventClass && event.eventClass == eventClass))) {
                return false;
            }

            return true;
        });

        return this;
    }

    trigger(_eventNames, data) {
        const _this = this;
        
        // get events and split them by ,
        const _events = _eventNames.split(',');
        
        // loop events
        _.each(_events, function(_event){
            _event = _event.trim();
            
            // split event by . for class name
            const eventName = _event.split('.')[0];
            const eventClass = _event.split('.')[1];
            
            const events = _.filter(_this.events, function(event){
                if(event.eventName == eventName && (!eventClass || (eventClass && event.eventClass == eventClass))) {
                    return true;
                }

                return false;
            });
            
            _.each(events, function(event){
                event.eventFunction(data);
            });
        });
        
        return this;
    }
};