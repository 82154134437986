import Core from '@/core/module';
import TweenMax from 'gsap';

export default class FigureCaption extends Core {
    init() {  
        this.addEventListeners(); 
    }

    show($el) {
        TweenMax.fromTo($el.find('figcaption'), .2, {bottom: -$el.find('figcaption').outerHeight()}, {bottom: 0});
    }

    hide($el) {
        TweenMax.fromTo($el.find('figcaption'), .2, {bottom: 0}, {bottom: -$el.find('figcaption').outerHeight(), onComplete: () => {
            $el.find('figcaption').css('bottom', -200);
        }});
    }

    addEventListeners() {
        const _this = this;

        this.$el.on('mouseover', function(){
            _this.show($(this));
        });

        this.$el.on('mouseout', function(){
            _this.hide($(this));
        });
    }
}