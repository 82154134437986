module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {

 _.each(labels, function(label, key) { ;
__p += '\n    <li>\n        <a href="#" data-disable-field=\'' +
((__t = ( JSON.stringify(label) )) == null ? '' : __t) +
'\'>\n            <i class="icon-close"></i>\n            <span>' +
((__t = ( label.text )) == null ? '' : __t) +
'</span>\n        </a>\n    </li>\n';
 }); ;


}
return __p
};
