import Vue from 'vue';
import Core from '@/core/module';
import GmapsLoader from '@/helpers/gmapsloader/';

export default class Mapgroups extends Core {
    init() {
        GmapsLoader.load(() => {
            import('./components/App.vue').then((app) => {
                const App = app.default;

                Vue.filter('highlight', function (value, query) {
                    if (!value) return ''

                    return value.replace(new RegExp(query, "gi"), match => {
                        return '<span class="highlightText">' + match + '</span>';
                    });
                });

                new Vue({
                    el: this.$el.find('> div')[0],
                    render: h => h(App)
                });
            });                 
        });
    }
}