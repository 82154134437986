import Core from '@/core/module';
import Gmapsloader from '@/helpers/gmapsloader'

export default class MapsWidget extends Core {
    init() {
        Gmapsloader.load(() => {
            this.buildMaps();
        });
    }

    buildMaps() {
        let centerLngLat = {
            lat: 52.370216,
            lng: 4.895168
        };

        const data = this.$el.data('map');
        let zoomlevel = 15;

        if(data) {
            centerLngLat = this.$el.data('map').center;

            if(data.zoomlevel) {
                zoomlevel = data.zoomlevel;
            }
        }

        this.map = new google.maps.Map(this.$el.find('.map')[0], {
            zoom: zoomlevel,
            center: centerLngLat,
            zoomControl: false,
            mapTypeControl: false,
            scaleControl: false,
            streetViewControl: false,
            rotateControl: false,
            fullscreenControl: false,
            scrollwheel: false,
            navigationControl: false,
            mapTypeControl: false,
            scaleControl: false,
            draggable: false,
            disableDoubleClickZoom: true
        });

        const image = {
            url: '/assets/img/marker.png',
            size: new google.maps.Size(40, 40),
            origin: new google.maps.Point(0, 0),
            anchor: new google.maps.Point(20, 40)
        };

        const marker = new google.maps.Marker({
            position: centerLngLat,
            icon: image,
            map: this.map
        });
    }
}