import Core from '@/core/module';
import GA from '@/helpers/ga';

import Template from './template.html';

export default class backtotop extends Core {
    init() {
        this.setDefaults({
            backtotopIsVisible: 'backtotop-is-visible',
            offset: 300,
            scrollTopDuration: 700
        });
        
        if(!this.$el) {
            this.$el = $(Template());
        } 

        $('body').append(this.$el);

        this.addEventListeners();
    }

    addEventListeners() {
        $(window).on('scroll.backToTop', () => {
            const scrollTop = $(window).scrollTop();

            if(scrollTop > this.options.offset) {
                this.$el.addClass(this.options.backtotopIsVisible);
            } else { 
                this.$el.removeClass(this.options.backtotopIsVisible);
            }
        });

        $(window).on('mousewheel.backToTop', () => {
            $('body, html').stop();
        });

        this.$el.on('click.backToTop', (e) => {
            e.preventDefault();

            $('body, html').stop().animate({ scrollTop: 0 }, this.options.scrollTopDuration);

            GA.event({
                category: 'button backtotop',
                action: 'click'
            });
        });
    }

    removeEventListeners() {
        $(window).off('scroll.backToTop');
        this.$el.on('click.backToTop');
    }
}