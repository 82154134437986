export default function($form) {
    let _object = {};
    const _array = $form.serializeArray();

    _array.forEach((item) => {
        if (_object[item.name] !== undefined) {
            if (!_object[item.name].push) {
                _object[item.name] = [_object[item.name]];
            }

            _object[item.name].push(item.value || '');
        }
        else {
            _object[item.name] = item.value || '';
        }
    });

    return _object;
}