// make jQuery global available
window.$ = $;

Base.modules = {};

import 'idempotent-babel-polyfill';
import '@/../sass/main.scss';
import 'dotdotdot';
import _ from 'lodash';
import 'magnific-popup';
import 'objectFitPolyfill';

import Base from '@/core/base';

window._ = {
    escape: _.escape,
    each: _.each
}

// import Newsletter from '@/modules/newsletter';
// import Cookiebar from '@/modules/cookiebar';
// import Fileupload from '@/modules/fileupload';
//
import BacktoTop from '@/modules/backtotop';
import Ajaxform from '@/modules/ajaxform';
import SelectHandler from '@/modules/selecthandler';
import MapsWidget from '@/modules/mapswidget';
import Collage from '@/modules/collage';
import Menu from '@/modules/menu';
import AanbodFilters from '@/modules/aanbodfilters';
import Imageslider from '@/modules/imageslider';
import ModuleMapGroups from '@/modules/mapgroups';
import ModuleHeaderSearch from '@/modules/headersearch';
import FigureCaption from '@/modules/figurecaption';
import Cookieconsent from '@triggerfishnl/cookieconsent';
import Search from '@/modules/search';
import CsrfToken from '@/modules/csrftoken';

new Cookieconsent({
    styleType: 'fixed-bottom',
    cookieConsentTypes: ['marketing', 'statistics', 'essential']
});

$('select').each(function(){
    new SelectHandler($(this));
});

$('.widget.widget--map').each(function(){
    const mapsWidget = new MapsWidget($(this));
});

new Collage($('.gallery-grid'));
Base.modules.menu = new Menu($('.mainnav'));
new BacktoTop();

new AanbodFilters($('.filters'));

$('[ajaxform]').each(function(){
    new Ajaxform($(this))
});
$('.csrf-token').each(function () {
   new CsrfToken($(this));
});

new FigureCaption($('figure'), {}, true);

$('.dotdotdot').dotdotdot();

$('.campaign-check').val(siteOptions.randomKey);

$('.sharer').on('click', 'a.togglePopup', function(e){
    e.preventDefault();
    window.open($(this).attr('href'), "Delen", 'width=520,height=350');
});

_.each($('.imageslider'), (el) => {
    new Imageslider($(el));
});

new ModuleMapGroups($('.locatiezoeker'));
new ModuleHeaderSearch($('.headersearch'));

new Search();

$('[popup]').magnificPopup({ type: 'image' });
