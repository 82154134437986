import Core from '@/core/module';

export default class CsrfToken extends Core {
    init() {
        const formName = this.$el.attr('form-name');
        const el = this.$el;

        fetch(`/_form/token?form=${formName}&html=0`).then((response) => {
            if (!response.ok) {
                return Promise.reject(response);
            }
            return response.text();
        }).then((data) => {
            el.attr('value', data);
        });
    }
}
