export default {
    set: function (name, data) {
        const date = new Date();
        date.setTime(date.getTime() + (data.expiration*24*60*60*1000));

        if(typeof data.value == 'object') {
            data.value = JSON.stringify(data.value);
        }

        if(!data.path) {
            data.path = '/';
        }

        document.cookie = `${name}=${data.value};expires=${date.toUTCString()};path=${data.path};`;
    },

    get: function(name, dataType){
        name = name + "=";
        const ca = document.cookie.split(';');
            
        for(let i=0; i<ca.length; i++) {
            let c = ca[i];

            while (c.charAt(0)==' ') c = c.substring(1);

            if (c.indexOf(name) != -1) {
                let cookieValue = c.substring(name.length,c.length),
                    nrOfSplits = ((cookieValue.match(/\|\|/g) || []).length);

                if(nrOfSplits > 0) {
                    cookieValue = cookieValue.split('||');
                }

                switch(dataType) {
                    case Number:
                        cookieValue = parseFloat(cookieValue);
                        break;
                    case Boolean:
                        cookieValue = cookieValue ? true : false;
                        break;
                    case JSON:
                        cookieValue = JSON.parse(cookieValue);
                        break;                        
                    case String:
                        cookieValue = cookieValue.toString();
                        break;
                }


                return cookieValue;
            } 
        }

        return null;
    },

    delete: function(name) {
        document.cookie = `${name}=; expires=Thu, 01 Jan 1970 00:00:01 GMT; path=/;`;
    }
}