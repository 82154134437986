export default (function(){
    const urlCall = function(url, data, method){
        return new Promise((accept, reject) => {
            $.ajax({
                url: `${window.siteOptions.APIPrefix}${url}`,
                data: data,
                dataType: 'JSON',
                method: (method && method == 'post') ? 'post' : 'get',
                success: accept,
                error: reject,
                cache: false
            });
        });
    }

    const get = function (url, data) {
        return new Promise((accept, reject) => {
            urlCall(url, data, 'get').then(accept, reject);
        });
    }

    const post = function(url, data){
        return new Promise((accept, reject) => {
            urlCall(url, data, 'post').then(accept, reject);
        });
    }

    return {get, post}; 
})();