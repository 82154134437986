import Core from '@/core/module';
import Base from '@/core/base';

import API from '@/helpers/api';
import SerializeObject from '@/helpers/formSerialize';
import FormContentRefresh from '@/modules/formcontentrefresh';
import Infinite from '@/modules/infiniteScroll';

import Template from './filters.html';
import TemplateLabel from './filter_labels.html';

import _ from "lodash";

export default class AanbodFilters extends Core {
    init() {  
        this.addEventListeners();

        this.filtersScope = {};

        if(this.$el.attr('filters') == 'widget') {
            this.getActivitiesWidget();
        }

        if(this.$el.attr('filters') == 'overview') {
            this.formContentRefresh = new FormContentRefresh(this.$el.find('form'), {
                contentContainer: $('[items]')
            });

            this.formContentRefresh.on('refresh', () => {
                this.infinite.checkScroll();
            });

            if(this.filterObject) { 
                this.updateFilters(this.filterObject.filter.typeswitch, () => {
                    this.formContentRefresh.updateContent();
                    this.updateFilters();
                });
            }
            else {
                this.updateFilters($('[activiteiten-switch] input:checked').attr('value'), () => {
                    this.formContentRefresh.updateContent();
                    this.updateFilters();
                });
            }

            this.infinite = new Infinite($('[items]'), {
                formData: this.$el.find('form'),
                method: 'post',
                hashtagRestore: false
            });
        }
    }

    setFilteredLabels() {
        return;
        let items = [];

        $('[filtercontainer] input:checked').each(function(){
            items.push({
                name: $(this).attr('name'),
                value: $(this).attr('value'),
                text: $(this).parent().text()
            });
        });

        const $el = $(TemplateLabel({labels: items}));

        $('.filter_labels').empty().append($el);
    }

    setFilters() {
        if(!this.filterObject) {
            return;
        }

        for (let key in this.filterObject.filter) {
            const value = this.filterObject.filter[key];
            const $input = $(`[name="${key}"]`);

            if($input.attr('type') == 'checkbox' || $input.attr('type') == 'radio') {
                $input.prop('checked', false);

                if(typeof value == 'object') {
                    $.each($input, function(){
                        if(value.indexOf($(this).attr('value')) >= 0) {
                            $(this).prop('checked', true);
                        }
                    });
                }
                else {
                    $.each($input, function(){
                        if($(this).attr('value') == value) {
                            $(this).prop('checked', true);
                        }
                    });
                }
            } else if ($input.attr('type') === 'text' || $input.is('select')) {
                $input.val(value);
            }
        }

        // this.setFilteredLabels();
    }

    get filterObject() {
        if(!window.location.hash) {
            return;
        }

        const array = window.location.hash.replace('#!', '').split('!');
        let _object = {};

        array.forEach((value) => {
            value = value.split(':');

            if(value[1] && value[1].indexOf('eyJ') == 0) {
                value[1] = JSON.parse(atob(value[1]));
            }

            _object[value[0]] = value[1];
        });

        return _object;
    }

    // set filters to hash tag data
    updateFilters(currentType, callback) {
        let data;

        if(currentType) {
            data = {
                typeswitch: currentType
            };

            this.currentType = currentType;
        }
        else {
            data = this.$el.find('form').serialize();
            this.currentType = this.$el.find('[activiteiten-switch] input:checked').val();
        }

        API.post(this.$el.data('filter-url'), data).then((json) => {
            $(document).find('[resultcount]').text(json.results);
            
            if(json.results == 1) {
                $(document).find('[resultcount-text]').text(`${json.results} resultaat`);
            }
            else {
                $(document).find('[resultcount-text]').text(`${json.results} resultaten`);
            }

            if(!this.currentType || this.setType != this.currentType) {
                this.setType = this.currentType;

                this.filtersScope = $.extend({}, this.filtersScope, json);

                this.renderFilters();

                this.setFilters();
            }
            else {
                _.each(json.filters, (filter) => {
                    _.each(filter.options, (option) => {
                        $(`input[name="${filter.name}"][value="${option.value}"]`).closest('label').parent().find('.count').text(option.count);
                    });
                });
            }

            if(typeof callback == 'function') {
                callback.call(this);
            }
        }, () => {

        });

        // this.setFilteredLabels();
    }

    renderFilters() {
        const el = Template({data: this.filtersScope});

        this.$el.find('[filtercontainer]').empty().append(el);
    }

    submitWidget() {
        if(typeof btoa == 'function') {
            window.location = `${this.$el.find('form').attr('action')}#!filter:${this.formToBase64()}`
        }
        else {
            window.location = `${this.$el.find('form').attr('action')}`;
        }
    }

    formToBase64() {
        const object = SerializeObject(this.$el.find('form'));

        return btoa(JSON.stringify(object));
    }

    getActivitiesWidget() {
        API.get(this.$el.data('activiteiten-url'), {
            type: this.$el.find('[activiteiten-switch] input:checked').val()
        }).then((json) => {
            this.$el.find('select[activiteiten-filter] option').remove();

            _.each(json, (item) => {
                this.$el.find('select[activiteiten-filter]').append(`<option value="${item.value}">${item.key}</option>`);
            });
        }, (err) => {
            console.log(err);
        });
    }

    addEventListeners() {
        const _this = this;

        if(this.$el.attr('filters') == 'widget') {
            this.$el.on('submit', 'form', (e) => {
                e.preventDefault();

                this.submitWidget();
            });

            $('[activiteiten-switch]').on('change', 'input', () => {
                this.getActivitiesWidget();
            });
        }

        if(this.$el.attr('filters') == 'overview') {
            this.$el.on('change', 'input, select', () => {
                window.location.hash = `!filter:${this.formToBase64()}`;
            });

            $(window).on('hashchange', () => {
                this.setFilters();
                this.updateFilters();
                this.formContentRefresh.updateContent();

                Base.modules.menu.close();
            });

            this.$el.on('click', '[showmore]', function(e){
                e.preventDefault();

                const $el = $(this);

                const $inputList = $(this).prev();

                $inputList.find('.item--hide:lt(5)').removeClass('item--hide');

                if($inputList.find('.item--hide').length == 0) {
                    $el.hide();
                }
            });

            $(document).on('change', '[activiteiten-switch-sub] input', function() {
                const value = $(this).attr('value');

                $(`[activiteiten-switch] input[value="${value}"]`).prop('checked', true).trigger('change');
            });
        }

        $(document).on('click', '.filter_bar, .filters .hamburger', (e) => {
            e.preventDefault();

            $('.page').toggleClass('filters-open');

            $(window).scrollTop(0);
        });

        $(document).on('click', '[data-disable-field]', function(e){
            e.preventDefault();

            const data = $(this).data('disable-field');

            $(`input[value="${data.value}"][name="${data.name}"]`).prop('checked', false).trigger('change');
        });
    }
}