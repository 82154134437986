import Events from '@/helpers/events';

export default class moduleCore {
    constructor($el, options, allowMultiple) {
        const EventController = new Events();
            this.events = EventController.events;
            this.on = EventController.on;
            this.off = EventController.off;
            this.trigger = EventController.trigger;

        if($el) {
            this.$el = $el;

            if(this.$el.length == 0) {
                return;
            }

            if(this.$el.length > 1 && allowMultiple == false) {
                console.error('$el cant be multiple');
                return;
            }
        }

        this.options = options;
        
        this.init();
    }

    init() {

    }

    setDefaults(options) {
        this.options = Object.assign(options, this.options);
    }
}