module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<div>\n    ';
 _.each(data.filters, function(filter, key) { ;
__p += '\n        <div class="field-group">\n            <span class="field-group__title h2">' +
((__t = ( filter.label )) == null ? '' : __t) +
'</span>\n\n            <ul class="inputlist inputlist--count">\n                ';
 _.each(filter.options, function(option, key) { ;
__p += '\n                <li class="';
 if(key > 5) { ;
__p += ' item--hide';
 } ;
__p += '">\n                    <label><input type="' +
((__t = ( filter.type )) == null ? '' : __t) +
'" name="' +
((__t = ( filter.name )) == null ? '' : __t) +
'" value="' +
((__t = ( option.value )) == null ? '' : __t) +
'"> <span><span>' +
((__t = ( option.key )) == null ? '' : __t) +
'</span></span></label>\n\n                    <span class="count">' +
((__t = ( option.count )) == null ? '' : __t) +
'</span>\n                </li>\n                ';
 }); ;
__p += '\n            </ul>\n            \n            ';
 if(filter.options.length > 5) { ;
__p += ' \n            <a href="#" class="link link--icon link--blue showmore" showmore><span>toon meer</span> <i class="icon-arrow-down"></i></a>\n            ';
 } ;
__p += '\n        </div>\n    ';
 }); ;
__p += '\n</div>';

}
return __p
};
