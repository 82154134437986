import Core from '@/core/module';
import Packery from 'packery';
import Infinite from '@/modules/infiniteScroll';
import FormContentRefresh from '@/modules/formcontentrefresh';

export default class Collage extends Core {
    init() {
        this.packery = new Packery('.gallery-grid', {
            itemSelector: '.link-block',
            transitionDuration: 0
        });

        this.formContentRefresh = new FormContentRefresh($('[formContentRefresh="story"]'));

        this.formContentRefresh
            .on('refresh', () => {
                this.packery.reloadItems();
                this.packery.layout();
                this.infinite.start();
            })
            .on('beforeRefresh', () => {
                this.infinite.forceStop();
                this.infinite.pause();
            });

        this.infinite = new Infinite(this.$el);

        this.infinite.on('loaded', ($html) => {
            this.packery.appended($html);
            this.packery.layout();
        });
    }
}