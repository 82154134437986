import Core from '@/core/module';
import GA from '@/helpers/ga';
import Cookie from '@/helpers/cookie';
import _ from "lodash";

import ErrorTemplate from './error.html';

export default class Ajaxform extends Core {
    init() {
        this.setDefaults({
            close: '.close',
            defaultTimeout: 10,
            scrollToError: true,
            tracking: true,
            preventDefault: true,
            formname: (this.$el.attr('form-name')) || 'unnamed form'
        });

        this.addEventListeners();
    }

    submit() {
        $.ajax({
            url: this.$el.attr('action'),
            method: (this.$el.attr('method') && this.$el.attr('method') == 'post') ? 'post' : 'get',
            data: this.$el.serialize(),
            dataType: 'JSON',
            success: (data) => {
                if(data.success) {
                    this.onSuccess(data);

                    this.trigger('success', data);

                    GA.event({
                        category: `ajaxform ${this.options.formname}`,
                        action: 'send',
                        label: 'successfull'
                    });
                }
                else {
                    this.onError('ajax', {errors: data.errors});

                    this.trigger('error');

                    GA.event({
                        category: `ajaxform ${this.options.formname}`,
                        action: 'send',
                        label: 'field error'
                    });
                }
            }, 
            error: (jqXHR, textStatus, errorThrown) => {
                this.onError('xhr', {jqXHR, textStatus, errorThrown});

                this.trigger('error');

                GA.event({
                    category: `ajaxform ${this.options.formname}`,
                    action: 'send',
                    label: `ajax error ${textStatus}`
                });
            }
        });
    }

    onSuccess(data) {
        window.location = data.url;
    }

    onError(type, error) {
        if(type == 'xhr') {
            alert('error: ', error.textStatus);
            console.error(error);
        }
        else if(type == 'ajax') {
            this.renderErrors(error.errors);
        }
    }

    renderErrors(errors) {
        _.each(errors, (error, key) => {
            const $el = this.$el.find(`[id="${error.inputName}"]`);

            $el.closest('.formfield').removeClass('valid').addClass('error');

            if(error) {
                const $ul = this.$el.find(`[data-errorgroup~="${error.inputName}"]`);

                this.$el.find(`li[data-inputname="${error.inputName}"]`).remove();

                $ul.append(ErrorTemplate(error));

                $ul.show();
            }
        });

        if(this.options.scrollToError) {
            const $errorEl = this.$el.find('[data-errorgroup]:visible:first');

            if($errorEl.length > 0) {
                $('html, body').animate({ scrollTop: $errorEl.offset().top-150 }, 750);
            }
        }
    }

    toggleErrorGroups() {
        this.$el.find('ul.errors').each(function(){
            const $el = $(this);

            $el.toggle(($el.find('li').length > 0));
        });
    }

    addEventListeners() {
        const _this = this;

        if(this.options.preventDefault) {
            this.$el
                .on('submit', (e) => {
                    e.preventDefault();
                    this.submit();
                });
        }
        
        this.$el
            .on('focus.ajaxForm', 'input, textarea, select', function(e) {
                const form = $(this).closest('form');
                const id = $(this).attr('id');
                const $formfield = $(this).closest('.formfield');

                $(this).closest('.formfield').removeClass('error');
                $(form).find(`[data-inputname="${id}"]`).remove();

                _this.toggleErrorGroups();
            })
            .on('change.ajaxForm', 'input[type="checkbox"], input[type="radio"]', function(e){
                $(this).closest('.formfield').removeClass('error');
                $(this).closest('.formfield').find('[data-inputname]').remove();

                _this.toggleErrorGroups();
            })
            .on('focus.ajaxForm', '.date input', function(e){
                $(this).closest('.date').closest('.formfield').removeClass('error');
                $(this).closest('.date').closest('.formfield').find('[data-inputname]').remove();

                _this.toggleErrorGroups();
            });
    }

    removeEventListeners() {
        this.$el
            .off('focus.ajaxForm change.ajaxForm focus.ajaxForm');
    }
}