import Core from '@/core/module';

export default class SelectHandler extends Core {
    init() {  
        this.addEventListeners();
    }

    addEventListeners() {
        this.$el.on('change.selectHandler', function(){
            const href = $(this).find('option:selected').attr('href');

            if(href) {
                window.location = href;
            }
        });
    }

    removeEventListeners() {
        this.$el.off('change.selectHandler');
    }
}