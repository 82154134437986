export default (function(){
    const check = function(){
        if(typeof dataLayer != 'object') {
            console.error('Tagmanager not implemented!');
            return false; 
        }

        return true;
    };

    const event = function (data, callback) {
        if(check()) {
            dataLayer.push({
                event: 'event',
                eventAction: data.action,
                eventCategory: data.category,
                eventLabel: data.label,
                eventValue: data.value,
                eventCallback: callback || function(){}
            });
        }
    };

    const pageview = function(data, callback){
        if(check()) {
            dataLayer.push({
                event: 'pageview',
                pageviewPath: data.path,
                eventCallback: callback || function(){}
            });
        }
    };

    return {pageview, event};
})();