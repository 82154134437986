import Core from '@/core/module';

export default class FormContentRefresh extends Core {
    init() {
        const id = this.$el.attr('formContentRefresh');

        this.setDefaults({
            id: id,
            contentContainer: $(`[formContentRefresh-id="${id}"]`),
            loadClass: 'contentrefresh--loading'
        });

        this.addEventListeners();
    }

    addEventListeners() {
        this.$el.on('submit', (e) => {
            e.preventDefault();

            this.updateContent();
        });

        this.$el.on('change', 'select, input', () => {
            this.$el.trigger('submit');
        });
    }

    stop() {
        if(this.ajax) {
            this.ajax.abort();

            this.$el.removeClass(this.options.loadClass);
        }
    }

    updateContent() {
        this.stop();

        this.options.contentContainer.addClass(this.options.loadClass);
        this.$el.addClass(this.options.loadClass);

        this.trigger('beforeRefresh');

        this.ajax = $.ajax({
            url: this.$el.attr('action'),
            method: (this.$el.attr('method') && this.$el.attr('method') == 'post') ? 'post' : 'get',
            data: this.$el.serialize(),
            dataType: 'html',
            success: (html) => {
                const $html = html;

                this.options.contentContainer.empty().append($html);

                this.options.contentContainer.removeClass(this.options.loadClass);
                this.$el.removeClass(this.options.loadClass);

                this.trigger('refresh', $html);
            },
            error: (jqXHR, textStatus, errorThrown) => {
                if(textStatus != 'abort') {
                    console.error(textStatus);
                }
            }
        })
    }
}